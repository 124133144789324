import Dashboard from "./Dashboard"
import { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { Cookies } from 'react-cookie'
import Api from "../util/Api/Api"

const DashboardPage = () => {
    const [loading, setLoading] = useState(false)
    const cookie = new Cookies();

    useEffect(() => {
        const access_token = cookie.get("access_token");
        if (!access_token) {
            window.location.href = "/Login";
        } else {
            Getmydata(access_token)
        }
    }, [])

    const Getmydata = (access_token) => Api.get('/user/mydata', {
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    }).then((data) => {
        setLoading(false)
    }).catch((error) => {
        console.log('error :>> ', error);
        setLoading(false)
        cookie.remove("access_token", { path: '/' });
        cookie.remove("refresh_token", { path: '/' });
    })

    return !loading ? <Dashboard /> : <div className='loading-page'>
        <LoadingOutlined style={{
            fontSize: 100,
            color: "#1890ff",
        }} />
    </div>
}

export default DashboardPage
